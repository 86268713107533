






































































import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class MenuWithPickerComponent extends Vue {
  private readonly TIME_MENU_WIDTH = 200;

  /**
   * Min max values for date start picker
   */
  @Prop({default: () => [{min: '', max: ''}]})
  public dateMinMax!: { min: string, max: string };
  /**
   * The label to be displayed
   */
  @Prop({default: '', type: String})
  public label!: string;
  /**
   * Should it be a datePicker or a timePicker
   */
  @Prop({default: 'date'})
  public type!: 'date' | 'time';
  @Prop({default: false})
  public clearable!: boolean;
  @Prop({default: ''})
  public errorMessages!: string | string[];
  @Prop({default: false})
  public alternativeDesign!: boolean;
  @Prop({default: ''})
  public placeHolder!: string;
  @Prop({default: false})
  public left!: boolean;
  @Prop({default: true})
  public attach!: boolean;

  private defaultPlaceholder: string = '';

  private internalError: string = '';
  /**
   * Menu flag for date menu
   */
  private menuFlag: boolean = false;

  private internalChange: boolean = false;

  private pickerValue: string = '';

  /**
   * The date from the datePicker as ISOString
   */
  @VModel({required: true})
  public returnValue!: string;

  private displayDate: string = '';

  public mounted() {
    this.watchValue();
  }

  /**
   * Humanized date string
   */
  private get displayValue(): string {
    return this.displayDate;
  }

  /**
   * This is executed if the textField is not readonly. In this case it is used in timePicker mode only
   * @param value
   * @private
   */
  private set displayValue(value: string) {
    if (this.type === 'date') {
      this.displayDate = this.validateDate(value);
    } else {
      this.displayDate = value;
    }
  }

  /**
   * Method to fill the timepicker-list with half-hour times
   */
  public get givenMinutes(): string[] {
    const times: string[] = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 2; m++) {
        times.push(`${h}`.padStart(2, '0') + ':' + `${m * 30}`.padStart(2, '0'));
      }
    }
    return times;
  }

  /**
   * Removes the inputs from the dateEnds
   */
  public clearPicker() {
    this.resetValues();
    this.openMenu();
  }

  private onInputChange(pickerValue: string) {
    if (pickerValue && pickerValue.length > 0) {
      this.internalChange = true;
      if (this.type === 'date') {
        this.returnValue = moment(pickerValue).toISOString();
        this.displayDate = moment(pickerValue).format(this.$t('GENERAL.DATE_FORMATS.DATE').toString());
      } else {
        this.returnValue = this.displayDate = pickerValue;
      }
      this.menuFlag = false;
    }
  }

  private validateDate(date: string): string {
    if (date) {
      this.internalError = '';
      const dateArray = date.split('.');
      const dateMoment = moment(`${dateArray[1]}-${dateArray[0]}-${dateArray[2]}`);
      if (!dateMoment.isValid() || dateArray[2].length < 4) {
        this.resetValues();
        return date;
      }
      const beforeMinDate = this.dateMinMax.min && dateMoment.isBefore(this.dateMinMax.min, 'day');
      const afterMaxDate = this.dateMinMax.max && dateMoment.isAfter(this.dateMinMax.max, 'day');
      if (beforeMinDate || afterMaxDate) {
        const messageType = beforeMinDate ? 'MINVALUE' : 'MAXVALUE';
        const edgeDate = (beforeMinDate ? moment(this.dateMinMax.min) : moment(this.dateMinMax.max))
            .format(this.$t('GENERAL.DATE_FORMATS.DATE').toString()).toString();
        // set both, min and max. The right value will be selected
        this.internalError = this.$t(`ERROR_MESSAGES.${messageType}`, {min: edgeDate, max: edgeDate}).toString();
        this.resetValues();
        return date;
      }
      this.pickerValue = dateMoment.clone().format(this.$t('GENERAL.DATE_FORMATS.PICKER_DATE').toString());
      this.returnValue = dateMoment.clone().toISOString();
      this.internalChange = true;
      // this is an edge case. If you type 29.2.YYYY and there is no 29.2 then the date is set to 1.3.YYYY. Maybe it
      // occurs somewhere else. Another case, the entry 012 for the month is valid too. Comparing numbers does not the job,
      // so compare the strings
      if (`${dateMoment.get('month') + 1}` !== dateArray[1]) {
        return dateMoment.format(this.$t('GENERAL.DATE_FORMATS.DATE').toString());
      }
    }
    return date;
  }

  /**
   * Formats the returned and displayed value of the textField in timepicker mode, when blurred
   * @private
   */
  private formatTime() {
    // do it if no errors occurred and the string has no maximum length of 5
    if (this.type === 'time') {
      if (this.displayValue.length > 0 && this.displayValue.length <= 5) {
        const parts = this.displayValue.split(':');
        if (parts.length === 1) {
          parts.push('');
        }
        this.internalError = '';
        // add a 0 in case the number is a single digit so something like 2:3 is possible and means 02:03
        // 4: --> 04:00 is possible too or even 4
        this.returnValue = this.displayValue = parts.map((part) => part.length < 2 ? part.length === 0 ? '00' : `0${part}` : part).join(':');
        this.internalChange = true;
      } else if (this.displayValue.length > 5) {
        this.internalError = this.$t('ERROR_MESSAGES.MAXLENGTH', {max: 5}).toString();
        this.returnValue = '';
        this.internalChange = true;
      }
      // date type
    } else {
      this.internalError = '';
      if (!this.returnValue && this.displayValue) {
        this.internalError = this.$t('ERROR_MESSAGES.INVALIDDATE').toString();
      }
    }
  }

  private get isClearable() {
    return !!(this.clearable && this.returnValue);
  }

  private openMenu() {
    (this.$refs.menuTextField as any).$refs.input.click();
  }

  private resetValues() {
    // Don't reset the values, in case there are none. Otherwise, the ErrorState of parentComponents will occur every
    // time someone writes a date manually, because the date is invalid, and the returnValues value is changed from
    // undefined/null to empty string
    if (this.returnValue) {
      this.pickerValue = '';
      this.returnValue = '';
    }
  }

  @Watch('returnValue')
  private watchValue() {
    // I'm not proud... other ideas?
    // Only changes from outer should trigger this. Otherwise, the usability is a bit bad. If a date is valid, it will
    // jump to the end of the string in the textfield
    if (!this.internalChange) {
      if (this.type === 'date') {
        this.defaultPlaceholder = this.$t('GENERAL.DATE_FORMATS.PLACEHOLDER').toString();
        if (this.returnValue) {
          // set displayDate and picker value. Don't use validateDate here, because in case there are max and min dates,
          // like in the cleanTimeComponent, that are based on the current day, the value of the picker would be invalid
          this.displayDate = moment(this.returnValue).format(this.$t('GENERAL.DATE_FORMATS.DATE').toString());
          this.pickerValue = moment(this.returnValue).format(this.$t('GENERAL.DATE_FORMATS.PICKER_DATE').toString());
        }
      } else {
        this.displayDate = this.returnValue;
      }
    } else {
      this.internalChange = false;
    }
  }
}
